import { CountryId, Store, StoreId } from '@/network/graphql.g'

interface AmioConfigData {
  storeId: Record<string, Record<string, AmioConfigDataDetail>>
}
export interface AmioConfigDataDetail {
  enable: boolean
  channelId: string
  lang: string
  logoUrl?: string
  theme: string
}

// const baseUrl = 'https://amio-assets.s3.eu-central-1.amazonaws.com/'

const enableStoreCountry: AmioConfigData | undefined = {
  storeId: {
    // [StoreId.Factcool]: {
    //   [CountryId.Sk]: {
    //     enable: true,
    //     channelId: '7165456837155506645',
    //     lang: 'sk-factcool',
    //     logoUrl: baseUrl + 'factcool_logo.png',
    //     theme: 'factcool'
    //   },
    //   [CountryId.Cz]: {
    //     enable: true,
    //     channelId: '7219796717276513149',
    //     lang: 'cs-factcool',
    //     logoUrl: baseUrl + 'factcool_logo.png',
    //     theme: 'factcool'
    //   }
    // },
    [StoreId.Bezvasport]: {
      // [CountryId.Sk]: {
      //   enable: true,
      //   channelId: '7206609045552908939',
      //   lang: 'sk-bezvasport',
      //   logoUrl: '',
      //   theme: 'bezvasport'
      // },
      [CountryId.Cz]: {
        enable: true,
        channelId: '7214600278128741975',
        lang: 'cs-bezvasport',
        logoUrl: '',
        theme: 'bezvasport'
      }
    }
  }
}

export const amioChatbotDataConfig = (store: Store): AmioConfigDataDetail => {
  return enableStoreCountry?.storeId?.[store?.id]?.[store?.market?.country]
}

export const isAmioChatbotSupported = (store: Store): boolean =>
  !!enableStoreCountry.storeId?.[store?.id]?.[store?.market?.country]?.enable

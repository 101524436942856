import { getHostData } from '@/providers/storeProvider'
import { CookieContext } from '@/utils/sessionService'
import { useCookie, Cookie } from 'next-cookie'
import { CookieSetOptions } from 'universal-cookie'

const defaultCookieOptions = {
  maxAge: 365 * 24 * 60 * 60, // 1 year
  path: '/'
}

type CookieMapping = {
  name: string
  options?: CookieSetOptions
}

export enum Cookies {
  mHubFcid = 'mhub_fcid',
  runningUserExperiments = 'running_user_experiments',
  clickedAds = 'clickedAds',
  factcoolAdminUser = 'factcool_admin_user'
}

const Headers = {
  [Cookies.mHubFcid]: 'mhub-fcid',
  [Cookies.runningUserExperiments]: 'running-user-experiments',
  [Cookies.clickedAds]: 'clicked-ads',
  [Cookies.factcoolAdminUser]: 'factcool-admin-user'
}

const defaultRequestCookies = [Cookies.mHubFcid, Cookies.runningUserExperiments]
const defaultResponseCookies = []

const cookies: Record<Cookies, CookieMapping> = {
  [Cookies.mHubFcid]: {
    name: Cookies.mHubFcid
  },
  [Cookies.runningUserExperiments]: {
    name: Cookies.runningUserExperiments
  },
  [Cookies.clickedAds]: {
    name: Cookies.clickedAds
  },
  [Cookies.factcoolAdminUser]: {
    name: Cookies.factcoolAdminUser
  }
}

export const getManagedCookiesForRequest = (
  cookie: Cookie,
  init: RequestInit
): string[] =>
  getManagedCookies(
    cookie,
    init,
    defaultRequestCookies,
    'managedCookiesForRequest'
  )

export const getManagedCookiesForResponse = (
  cookie: Cookie,
  init: RequestInit
): string[] =>
  getManagedCookies(
    cookie,
    init,
    defaultResponseCookies,
    'managedCookiesForResponse'
  )

const getManagedCookies = (
  cookie: Cookie,
  init: RequestInit,
  defaultCookies: Cookies[],
  headerName: string
): string[] => {
  if (cookie) {
    let managedCookies: string[] = []
    try {
      const text = init?.headers[headerName]
      if (text) {
        managedCookies = JSON.parse(text)
      }
    } catch {
      managedCookies = []
    }
    if (init?.headers) {
      delete init.headers[headerName]
    }
    return [...defaultCookies, ...managedCookies]
  } else {
    return []
  }
}

export const addHeadersFromCookies = (
  cookie: Cookie,
  init: RequestInit,
  managedCookies: string[]
): RequestInit => {
  if (!init) {
    init = {
      headers: {}
    }
  }
  if (cookie) {
    managedCookies.forEach((cookieName) => {
      const value = cookie.get(cookieName)
      if (value) {
        init.headers[Headers[cookieName]] =
          typeof value === 'string' || value instanceof String
            ? value
            : JSON.stringify(value)
      }
    })
  }
  return init
}

export const addCookiesFromHeaders = (
  cookie: Cookie,
  response: Response,
  managedCookies: string[]
): void => {
  if (cookie && response?.headers) {
    const cookiesText = response?.headers?.get('set-cookie') ?? ''
    managedCookies.forEach((cookieName) => {
      const re = `${cookieName}=(.*?)(;|,|$)`
      const value =
        cookiesText.match(re)?.[1] ??
        response?.headers?.get(Headers[cookieName])
      if (value) {
        cookie.set(cookieName, decodeURI(value), {
          ...defaultCookieOptions,
          ...(cookies[cookieName]?.options ?? {})
        })
      }
    })
  }
}

export const removeCookie = (
  name: string,
  cookie: Cookie,
  options?: CookieSetOptions
): void => {
  if (cookie) {
    cookie.set(name, '', {
      ...{
        expires: new Date(),
        path: '/'
      },
      ...(options ?? {})
    })
  }
}

export const removeCookieWithoutCookie = (
  name: string,
  context?: CookieContext,
  options?: CookieSetOptions
): void => {
  removeCookie(name, useCookie(context), options)
}

export const removeMdcrfCookie = (
  context: CookieContext,
  host: string
): void => {
  const { domain, tld } = getHostData(host)
  removeCookieWithoutCookie('mdcrf', context, { domain: `.${domain}.${tld}` })
}

export const initializeCookies = (context?: CookieContext): void => {
  const cookie = useCookie(context)
  for (const cookieItem in Cookies) {
    const cookieName = Cookies[cookieItem]
    if (cookie.has(cookieName)) {
      cookie.set(cookieName, cookie.get(cookieName, { doNotParse: true }), {
        ...defaultCookieOptions,
        ...(cookies[cookieName]?.options ?? {})
      })
    }
  }
}

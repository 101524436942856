import { Cookie } from 'next-cookie'
import { FetchError } from './auth/fetchError'
import { isSSR, logApi } from './utils'
import {
  addCookiesFromHeaders,
  addHeadersFromCookies,
  getManagedCookiesForRequest,
  getManagedCookiesForResponse
} from './graphQlCookieManager'
import { DomainNames } from '@/types/stores'
import { signOut, signOutDirect } from './auth/authenticator'
import { getTld, isAkamai } from './domainHelper'
import sha256 from 'crypto-js/sha256'
import hex from 'crypto-js/enc-hex'

export const fetchDataWithCookie =
  (cookie: Cookie) =>
  (input: RequestInfo, init?: RequestInit): Promise<Response> =>
    baseFetchData(input, init, cookie)

export const fetchData = (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => baseFetchData(input, init, null)

const baseFetchData = async (
  input: RequestInfo,
  init: RequestInit,
  cookie: Cookie
): Promise<Response> => {
  const managedCookiesForRequest = getManagedCookiesForRequest(cookie, init)
  const managedCookiesForResponse = getManagedCookiesForResponse(cookie, init)
  init = addHeadersFromCookies(cookie, init, managedCookiesForRequest)

  const log = new FetchError(input, init)
  if (isGraphQlRequest(input)) {
    try {
      if (init?.headers?.['x-cachable']) {
        init.headers['request-hash'] = sha256(init?.body?.toString()).toString(
          hex
        )
      } else if (init?.headers?.['x-product-id']) {
        delete init.headers['request-hash']
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }
  if (isGraphQlRequest(input) || isAuthRequest(input)) {
    init.credentials = 'omit' // do not send cookies
  }
  try {
    const response = await fetch(input, init)
    addCookiesFromHeaders(cookie, response, managedCookiesForResponse)
    log.addEvent('status', response.status)
    log.setStatus(response.status)
    if (response.ok) {
      return response
    } else {
      const error = new Error(`Response status ${response.status}`)
      log.addEvent(`fetchData ${response.status} error`, error)
      if (response.status === 401) {
        if (log.signOutOn401()) {
          if (isSSR) {
            signOutDirect(cookie)
          } else {
            signOut(log.getBaseUrl())
          }
        }
      }
      try {
        log.setErrorText(await response.text())
        // eslint-disable-next-line no-empty
      } catch {}
      throw error
    }
  } catch (e) {
    log.addEvent('fetchData error', e)
    if (!log.getErrorText()) {
      log.setErrorText(e?.toString() ?? '500 Internal Server Error')
    }
    isAbortError(e) && log.setAbortError(true)
    // eslint-disable-next-line no-console
    logApi && console.log(log)
    throw log
  }
}

export const isNewVersionGraphQL = (cookie: Cookie): boolean => {
  return getUrlGraphQL(cookie) === process.env.API_URL_NEW
}

function getUrlGraphQL(cookie?: Cookie): string {
  let isEmployee = false

  try {
    if (cookie) {
      isEmployee = cookie.cookie['cookies']['isEmployee'] === 'true'
    }
    // eslint-disable-next-line no-empty
  } catch {}

  const apiUrl = !isEmployee ? process.env.API_URL_NEW : process.env.API_URL

  // eslint-disable-next-line no-console
  console.info('Api url: ', apiUrl)

  return apiUrl
}

export const isAbortError = (e: unknown): boolean =>
  e?.['name'] === 'AbortError' || (e instanceof FetchError && e.isAbortError())

export const isGraphQlRequest = (input: RequestInfo): boolean =>
  input.toString().endsWith(process.env.FACTCOOL_GRAPHQL_API_ENDPOINT)

const isAuthRequest = (input: RequestInfo): boolean =>
  input.toString().endsWith(process.env.FACTCOOL_TOKEN_ENDPOINT)

export const getGraphQlUrl = (
  domain: string,
  subdomain: string,
  cookie?: Cookie
): string =>
  getUrl(
    domain,
    subdomain,
    process.env.FACTCOOL_GRAPHQL_API_ENDPOINT,
    getUrlGraphQL(cookie)
  )

export const getAuthUrl = (domain: string, subdomain: string): string =>
  getUrl(domain, subdomain, process.env.FACTCOOL_TOKEN_ENDPOINT)

export const getServerUrl = (domain: string, subdomain: string): string =>
  getUrl(domain, subdomain, process.env.FACTCOOL_SERVER_URL)

const getUrl = (
  domain: string,
  subdomain: string,
  baseUrl: string,
  apiUrlAddress?: string
) => {
  const useAkamai = isAkamai(domain, subdomain)
  const apiUrl = useAkamai
    ? process.env.AKAMAI_API_URL
    : apiUrlAddress
    ? apiUrlAddress
    : process.env.API_URL

  if (
    useAkamai &&
    (domain === DomainNames.Bezvasport || domain === DomainNames.Frogies)
  ) {
    const useWWW =
      domain === DomainNames.Bezvasport || domain === DomainNames.Frogies
    return `${apiUrl
      .replace('{{domain}}', `${useWWW ? 'www.' : ''}${domain}`)
      .replace('{{tld}}', getTld(subdomain))}${baseUrl}`
  } else {
    return `${apiUrl
      .replace('{{domain}}', `${subdomain}.${domain}`)
      .replace('{{tld}}', 'com')}${baseUrl}`
  }
}

export const getAuthorizationHeader = (accessToken: string): string =>
  `Bearer ${accessToken}`

export const getHeaders = (
  accessToken: string,
  domain: string,
  subdomain: string,
  host: string
): HeadersInit => {
  const headers: HeadersInit = { 'Access-Control-Allow-Origin': '*' }
  if (accessToken) {
    headers.Authorization = getAuthorizationHeader(accessToken)
  }
  headers.BaseUrl = host
  headers.Market = subdomain
  headers.Store = domain
  return headers
}

import styled, { css } from 'styled-components'
import { Box } from '@/components/atoms/Grid'
import { validFor } from '@/theme/validFor'

export enum PageContentWrapperBackgroundStyle {
  normal,
  table
}

type PageContentProps = {
  backgroundStyle: PageContentWrapperBackgroundStyle
}

interface ContentPaddingType {
  wide: number
  desktop: number
  tablet: number
  mobile: number
}

export const ContentPaddings: ContentPaddingType = {
  desktop: 56,
  mobile: 10,
  tablet: 20,
  wide: 0
}

const PageContentWrapper = styled<PageContentProps>(Box)`
  ${validFor.desktop`
    padding: 0 ${ContentPaddings.desktop}px;
  `}
  ${validFor.wide`
    padding: 0 ${ContentPaddings.wide}px;
  `}
  ${validFor.mobile`
    padding: 0 ${ContentPaddings.mobile}px;
  `}
  ${validFor.tablet`
    padding: 0 ${ContentPaddings.tablet}px;
  `}
  ${(props) => makeBackgroundColor(props.backgroundStyle)};
`

const makeBackgroundColor = (style: PageContentWrapperBackgroundStyle): css => {
  switch (style) {
    case PageContentWrapperBackgroundStyle.table:
      return css`
        background-color: ${(props) => props.theme.colors.background};
        ${validFor.mobile`
          background-color: rgba(0, 0, 0, 0.05);
        `}
      `
    case PageContentWrapperBackgroundStyle.normal:
      return css`
        background-color: ${(props) => props.theme.colors.background};
      `
  }
}

export default PageContentWrapper

import React from 'react'
import {
  ExperimentType,
  useVariant,
  DatabrekersScriptVariant
} from './experimentProvider'
import Script from 'next/script'
import {
  trackerDatabreakerScript,
  trackerDatabreakerExperiment
} from '@/core/databreakerTracker'
import { Store } from '@/network/graphql.g'
import { useCookie } from 'next-cookie'

const DatabreakersContext = React.createContext<DatabreakersContextType>(null)

type DatabreakersContextType = {
  useDatabreaker: boolean
}

type DataBreakerProviderProps = {
  store: Store
}

const DatabreakersProvider: React.FC<DataBreakerProviderProps> = ({
  store,
  children
}) => {
  const cookie = useCookie()

  const switchVariant = useVariant(ExperimentType.DatabrekersScript)

  const isDatabreakerScript = getDataBreakerCountryId(store, false) !== null

  const isDatabreakerExperimentScript =
    getDataBreakerCountryId(store, true) !== null

  const isDatabreakerExperiment =
    switchVariant === DatabrekersScriptVariant.VariantA &&
    isDatabreakerExperimentScript

  if (isDatabreakerExperimentScript) {
    const cookieName = 'databreakers_experiment'
    const cookieValue =
      store?.id +
      '_' +
      store?.market?.country +
      '_active_' +
      isDatabreakerExperiment

    const cookieOptions = {
      maxAge: 24 * 60 * 60, // 30 days
      path: '/'
    }
    cookie.set(cookieName, cookieValue, cookieOptions)
  }

  return (
    <DatabreakersContext.Provider
      value={{
        useDatabreaker: isDatabreakerExperiment || isDatabreakerScript
      }}
    >
      {isDatabreakerExperimentScript && (
        <Script
          src={`https://download.databreakers.com/clients/${getDataBreakerCountryId(
            store,
            true
          )}/default.js`}
          strategy="beforeInteractive"
        />
      )}

      {isDatabreakerScript && (
        <Script
          src={`https://download.databreakers.com/clients/${getDataBreakerCountryId(
            store,
            false
          )}/default.js`}
          strategy="beforeInteractive"
        />
      )}

      {children}
    </DatabreakersContext.Provider>
  )
}

const getDataBreakerCountryId = (
  store: Store,
  isExperiment: boolean
): string => {
  const tracker = isExperiment
    ? trackerDatabreakerExperiment
    : trackerDatabreakerScript

  return tracker.storeId?.[store?.id]?.[store?.market?.country] ?? null
}

export { DatabreakersProvider }

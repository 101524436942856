import { Order, Product, ProductSize } from '@/network/graphql.g'
import { ProductWithSize } from '@/types/productWithSize'
import { ImageMode } from '@/utils/imageExtension'
import { getMhubProductData, MhubProductData } from './productManager'

type MHubCartData = {
  products: MhubCartProductData[]
}

type MhubCartProductData = {
  available: number
  list: string
  position: number
  quantity: number
  variant: string
  variantId: number
  isStock: boolean
} & MhubProductData

type MhubCartEventProductData = {
  variantId?: string
  variant?: string
  isStock?: boolean
  available?: number
  deliveryTime?: {
    from: number
    to: number
  }
} & MhubProductData

export type MHubCart = {
  cart: MHubCartData
}

type MhubCartEvent = {
  event: string
  action:
    | {
        add: {
          products: MhubCartEventProductData[]
        }
      }
    | {
        remove: {
          products: MhubCartEventProductData[]
        }
      }
}

export const getEmptyMhubCart = (imageMode: ImageMode): MHubCart =>
  getMhubCart(null, imageMode)

export const getMhubCart = (cart: Order, imageMode: ImageMode): MHubCart => {
  return {
    cart:
      cart?.products?.length > 0
        ? {
            products:
              cart?.products?.map((item, index) => ({
                ...getMhubProductData(item.product, imageMode),
                ...{
                  available: item.isStock ? item.availability : 0,
                  list: 'cart.widget',
                  position: index + 1,
                  quantity: item.count,
                  variant: item.size?.value,
                  variantId: item.size?.id ? +item.size.id : null,
                  isStock: item.isStock
                }
              })) ?? []
          }
        : null
  }
}

const getMhubCartEventProductData = (
  product: Product,
  size: ProductSize,
  imageMode: ImageMode,
  cart: Order
): MhubCartEventProductData => {
  const item = cart.products.find((item) => {
    return item.product.id === product.id && item.size.id === size.id
  })

  return {
    ...getMhubProductData(product, imageMode),
    ...{
      variantId: size?.id,
      variant: size?.value,
      isStock: size?.isStock,
      available: size?.available ?? 0,
      quantity: item?.count ?? 0,
      deliveryTime: {
        from: size?.delivery?.lowest,
        to: size?.delivery?.highest
      }
    }
  }
}

export const getMhubCartAddEvent = (
  product: Product,
  size: ProductSize,
  imageMode: ImageMode,
  cart: Order
): MhubCartEvent => ({
  event: 'action.add.products',
  action: {
    add: {
      products: [getMhubCartEventProductData(product, size, imageMode, cart)]
    }
  }
})

export const getMhubCartAddMultipleEvent = (
  products: ProductWithSize[],
  imageMode: ImageMode,
  cart: Order
): MhubCartEvent => ({
  event: 'action.add.products',
  action: {
    add: {
      products: products.map((product) =>
        getMhubCartEventProductData(product, product.size, imageMode, cart)
      )
    }
  }
})

export const getMhubCartRemoveEvent = (
  data: { product: Product; size: ProductSize }[],
  imageMode: ImageMode,
  cart: Order
): MhubCartEvent => ({
  event: 'action.remove.products',
  action: {
    remove: {
      products: data.map(({ product, size }) =>
        getMhubCartEventProductData(product, size, imageMode, cart)
      )
    }
  }
})

import { FC, useContext, useEffect, useRef } from 'react'
import { Box, Flex } from 'components/atoms/Grid'
import styled from 'styled-components'
import { Tiny2, Body2 } from '@/components/atoms/typography'
import Link from '@/components/atoms/link'
import Image from 'next/image'
import { useAsset, Asset } from '@/providers/assetsProvider'
import {
  HomeBanner,
  NavigationItem,
  PremiumManufacturer
} from '@/network/graphql.g'
import { ID } from 'graphql-ws'
import BannersLayout, {
  BannersType
} from '@/components/organisms/bannersLayout'
import MenuBadge from '@/components/atoms/menuBadge'
import { useRouter } from 'next/router'
import { ContentPaddings } from './pageContentWrapper'
import { ContentWrapper } from './container'
import { SectionSeparator } from '../atoms/separator'
import { StoreContext } from '@/providers/storeProvider'
import useTranslation from 'next-translate/useTranslation'
import { Translate } from 'next-translate'
import { CategoryIconsList } from '@/components/atoms/svg/categoryIcons/categoryIconsList'
import PremiumBrands from '../molecules/premiumBrands'
import { useMainNavigationDispatch } from '@/providers/mainNavigationMenuProvider'

type DesktopDropdownMenuProps = {
  subCategories: NavigationItem[]
  banners?: HomeBanner[]
  premiumManufacturers?: PremiumManufacturer[]
  navigationItems: NavigationItem[]
  name?: string
} & Rest

const DesktopDropdownMenu: FC<DesktopDropdownMenuProps> = ({
  subCategories,
  banners,
  premiumManufacturers,
  ...rest
}) => {
  const { asset } = useAsset()
  const { t } = useTranslation('common')
  const { isFrogies } = useContext(StoreContext)
  const subcategoriesElements = makeSubcategories(
    subCategories,
    t,
    isFrogies,
    premiumManufacturers
  )
  const router = useRouter()
  const dropDownRef = useRef<HTMLDivElement>()

  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout = null
    const startNavigation = () => {
      if (dropDownRef?.current?.style) {
        dropDownRef.current.style.display = 'none'
        timeoutHandle = setTimeout(() => {
          if (dropDownRef?.current?.style) {
            dropDownRef.current.style.display = ''
          }
        }, 100)
      }
    }

    router.events.on('routeChangeComplete', startNavigation)
    router.events.on('routeChangeError', startNavigation)

    return () => {
      router.events.off('routeChangeComplete', startNavigation)
      router.events.off('routeChangeError', startNavigation)
      clearTimeout(timeoutHandle)
    }
  }, [router])

  const addCategoryBanners = (
    bannersItems: (HomeBanner & { category?: string })[],
    category: string
  ) => {
    const bannersCategory = [...bannersItems].map((item) => {
      if (!item?.category) {
        return { ...item, category }
      }
      return { ...item }
    })
    return bannersCategory
  }
  const categoryBanners = addCategoryBanners(banners, rest.name)

  return (
    <StyledDropdownMenu
      ref={dropDownRef}
      data-cy="desktopDropdownMenu"
      {...rest}
    >
      <ContentWrapper
        px={{
          wide: ContentPaddings.wide,
          desktop: ContentPaddings.desktop
        }}
      >
        <SectionSeparator section={true} />
        <Box p={'5px 20px 21px 30px'}>
          <StyledImageWrapper>
            <Image
              src={asset(Asset.BGDropdown)}
              width={745}
              height={552}
              unoptimized
            />
          </StyledImageWrapper>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            position="relative"
          >
            {subcategoriesElements}
            {banners && (
              <Flex flexDirection="column" pt={3} minWidth={215} maxWidth={215}>
                <BannersLayout
                  banners={
                    isFrogies ? categoryBanners : categoryBanners?.slice(0, 2)
                  }
                  bannerDst={BannersType.Dropdown}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      </ContentWrapper>
    </StyledDropdownMenu>
  )
}

const makeSubcategories = (
  subcategories: NavigationItem[],
  t: Translate,
  isFrogies: boolean,
  premiumManufacturers?: PremiumManufacturer[]
): JSX.Element => {
  const columns = makeColumns(subcategories)

  return (
    <>
      {Object.entries(columns).map(([key, subcategories]) => (
        <Flex key={key} flexDirection="column" alignItems="start">
          {subcategories.map((subcategory) => makeSubcategory(subcategory))}
        </Flex>
      ))}
      {!isFrogies && premiumManufacturers?.length > 0 && (
        <Flex flexDirection="column" alignItems="start">
          <Box data-cy="headerItems">
            <StyledItemsHeader highlight={false}>
              {t('Menu.premium')}
            </StyledItemsHeader>
            <PremiumBrands pb={2} premiumManufacturers={premiumManufacturers} />
          </Box>
        </Flex>
      )}
    </>
  )
}

const makeItems = (items: NavigationItem[]) =>
  items?.map(({ name, url, isHighlight, desktopBadge, categoryId }) => {
    const { slugPath } = useMainNavigationDispatch()
    return (
      <StyledLink key={url} href={url}>
        {CategoryIconsList?.[categoryId] && CategoryIconsList?.[categoryId]}
        <StyledItem
          highlight={isHighlight}
          showIcons={CategoryIconsList?.[categoryId]}
          isActive={slugPath.find((path) => path.id === categoryId)}
        >
          {name}
        </StyledItem>
        {desktopBadge && (
          <StyledMenuBadge imageUrl={desktopBadge} height={20} mt={-10} />
        )}
      </StyledLink>
    )
  })

const makeSubcategory = ({
  name,
  children,
  url,
  isHighlight,
  desktopBadge,
  categoryId
}: NavigationItem) => {
  const { slugPath } = useMainNavigationDispatch()

  return (
    <Box key={url} cursorOnHover={url ? true : false} data-cy="headerItems">
      {url ? (
        <StyledLink href={url}>
          <StyledItemsHeader
            highlight={isHighlight}
            isActive={slugPath.find((path) => path.id === categoryId)}
          >
            {name}
          </StyledItemsHeader>
          {desktopBadge && (
            <StyledMenuBadge imageUrl={desktopBadge} height={20} />
          )}
        </StyledLink>
      ) : (
        <StyledItemsHeader
          highlight={isHighlight}
          isActive={slugPath.find((path) => path.id === categoryId)}
        >
          {name}
        </StyledItemsHeader>
      )}
      {makeItems(children)}
    </Box>
  )
}

const makeColumns = (
  subcategories: NavigationItem[]
): Record<ID, NavigationItem[]> => {
  const maxNumberOfItemsInRow = 5
  const subcategoriesCopy: NavigationItem[] = [...subcategories]
  let columns: Record<ID, NavigationItem[]> = {}

  if (subcategoriesCopy.length <= maxNumberOfItemsInRow) {
    columns = makeArrayFrom(subcategoriesCopy)
  } else {
    const remainingItems = subcategoriesCopy.splice(0, maxNumberOfItemsInRow)
    columns = makeArrayFrom(remainingItems)
    subcategoriesCopy.forEach((item, index) => {
      const currentIndex = index % maxNumberOfItemsInRow
      columns[currentIndex].push(item)
    })
    /*while (subcategoriesCopy.length > 0) {
      subcategoriesCopy.sort((a, b) => a.children?.length - b.children?.length)
      const countMap: Array<Record<string, number>> = Object.values(
        columns
      ).map((subcategory, offset) => ({
        index: offset,
        itemsCount: subcategoriesCopy.reduce(
          (midResult, { children }) => midResult + children?.length || 0,
          0
        )
      }))
      countMap.sort((a, b) => b.itemsCount - a.itemsCount)
      const nextPortion = subcategoriesCopy.splice(0, maxNumberOfItemsInRow)
      nextPortion.forEach(
        (item) =>
          (columns[item.categoryId] = [
            ...(columns[item.categoryId] || []),
            item
          ])
      )
    }*/
  }
  return columns
}

const makeArrayFrom = (
  subcategories: NavigationItem[]
): Record<ID, NavigationItem[]> =>
  subcategories.reduce((prev, current, index) => {
    prev[index] = [current]
    return prev
  }, {})

export default DesktopDropdownMenu

const StyledItemsHeader = styled(Body2)`
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.accent
      : props.theme.colors.onBackground};
  margin-bottom: ${(props) => (props.isActive ? '14px' : '16px')};
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    width: 16px;
    height: 2px;
    background-color: ${(props) =>
      props.highlight
        ? props.theme.colors.accent
        : props.theme.colors.onBackground};
  }
`

const StyledDropdownMenu = styled(Box)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  position: absolute;
  right: 0;
  top: 100%;
  max-height: 0px;
  overflow: hidden;
  left: 0;
  z-index: 100;
  border-bottom: none;
  margin-top: -1px;
`

const StyledImageWrapper = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
`

const StyledItem = styled(Tiny2)`
  margin-top: 6px;
  margin-bottom: ${(props) => (props.isActive ? '4px' : '6px')};
  margin-left: ${({ showIcons }) => showIcons && '12px'};
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.accent
      : props.theme.colors.onBackground};
  opacity: ${(props) => (props.highlight ? 1 : 0.95)};
  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.colors.onBackground};
    cursor: pointer;
    &::after {
      background-color: ${(props) => props.theme.colors.onBackground};
    }
  }
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    width: 12px;
    height: 2px;
    background-color: ${(props) =>
      props.highlight
        ? props.theme.colors.accent
        : props.theme.colors.onBackground};
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

const StyledMenuBadge = styled(MenuBadge)`
  padding-left: 5px;
  padding-top: 10px;
`

import React, { FC, forwardRef, useEffect, useMemo, useRef } from 'react'
import Head from 'next/head'
import { BreakpointKey, getMedia } from '@/theme/breakpoints'
import styled from 'styled-components'
import { Box } from './Grid'
import { PlaceholderColor } from './productCardPlaceholder'
import { applyImagePolicy, ImageQuality } from '@/utils/imageExtension'

type PictureProps = {
  src: string
  desktop?: string
  tablet?: string
  mobile?: string
  preload?: boolean
  preloadDefaultMedia?: BreakpointKey
  lazy?: boolean
  useCdn?: boolean
  quality?: ImageQuality
} & Rest

type Placeholder = {
  ratio: number | { desktop: number; tablet: number; mobile: number }
  transition?: boolean | number
  disableBackground?: boolean
}

type ImageProps = {
  src: string
  lazy: boolean
  onLoad: () => void
}

const Image = forwardRef<Rest, ImageProps>(
  //todo uodate it by using Inage next
  ({ src, lazy, onLoad, ...rest }, ref) => (
    <StyledImg
      ref={ref as React.MutableRefObject<HTMLImageElement>}
      src={src}
      loading={lazy ? 'lazy' : null}
      onLoad={onLoad}
      {...rest}
    />
  )
)

type PreloadLinkProps = {
  src: string
  media: string
}

export const PreloadLink: FC<PreloadLinkProps> = ({ src, media }) => (
  <Head>
    <link rel="preload" href={src} as="image" media={media} />
  </Head>
)

type PicturePlaceholderProps = React.PropsWithChildren<{
  placeholder: Placeholder
  elementClass?: string
}>

const PicturePlaceholder: FC<PicturePlaceholderProps> = ({
  placeholder,
  elementClass,
  children
}) => {
  const ref = useRef<HTMLImageElement>(null)
  const timeoutHandle = useRef<NodeJS.Timeout>()

  const getElement = (): HTMLImageElement => {
    if (elementClass) {
      return document.querySelector(`img.${elementClass}`)
    } else {
      return ref.current
    }
  }

  const getParent = (): HTMLDivElement =>
    getElement()?.closest('div.picture-placeholder-wrapper') as HTMLDivElement

  const getAbsoluteElement = (): HTMLElement => {
    if (elementClass) {
      return getParent().firstElementChild as HTMLElement
    } else {
      return getElement()
    }
  }

  useEffect(() => {
    if (getElement()?.complete) {
      onLoad()
    }
    return () => {
      clearTimeout(timeoutHandle.current)
    }
  }, [])

  const onLoad = () => {
    if (getElement()) {
      if (placeholder.transition) {
        getElement().style.opacity = '1'
      }
      if (getAbsoluteElement() && getParent()) {
        getAbsoluteElement().style.position = 'relative'
        getParent().style.paddingBottom = '0'
      }
      timeoutHandle.current = setTimeout(() => {
        if (getParent()) {
          getParent().style.backgroundColor = 'transparent'
        }
      }, 1000)
    }
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return React.cloneElement(child as React.ReactElement<any>, {
        onLoad,
        ref
      })
    }
    return child
  })

  const paddingBottom = useMemo(() => {
    const toPercent = (value) => `${value * 100}%`
    if (typeof placeholder.ratio === 'object' && placeholder.ratio !== null) {
      return {
        desktop: toPercent(placeholder.ratio.desktop),
        tablet: toPercent(placeholder.ratio.tablet),
        mobile: toPercent(placeholder.ratio.mobile)
      }
    } else {
      return toPercent(placeholder.ratio)
    }
  }, [placeholder.ratio])

  return (
    <Wrapper
      pb={paddingBottom}
      disableBackground={placeholder.disableBackground}
      transition={
        placeholder.transition
          ? typeof placeholder.transition === 'number'
            ? placeholder.transition
            : 0.3
          : false
      }
      className="picture-placeholder-wrapper"
      elementClass={elementClass}
    >
      {childrenWithProps}
    </Wrapper>
  )
}

const InnerPicture = forwardRef<
  Record<string, never>,
  PictureProps & {
    onLoad?: () => void
  }
>(
  (
    {
      src: baseSrc,
      desktop: baseDesktop,
      tablet: baseTablet,
      mobile: baseMobile,
      preload = false,
      preloadDefaultMedia,
      lazy = true,
      useCdn = true,
      quality,
      onLoad,
      ...rest
    },
    ref
  ) => {
    const prepareUrl = (url: string) =>
      url ? applyImagePolicy(url, { useCdn, quality }) : null

    const src = useMemo(() => prepareUrl(baseSrc), [baseSrc])
    const desktop = useMemo(() => prepareUrl(baseDesktop), [baseDesktop])
    const tablet = useMemo(() => prepareUrl(baseTablet), [baseTablet])
    const mobile = useMemo(() => prepareUrl(baseMobile), [baseMobile])

    return (
      <>
        {!desktop && !tablet && !mobile ? (
          <>
            {preload && (
              <PreloadLink
                src={src}
                media={
                  preloadDefaultMedia ? getMedia(preloadDefaultMedia) : 'all'
                }
              />
            )}
            <Image ref={ref} src={src} lazy={lazy} onLoad={onLoad} {...rest} />
          </>
        ) : (
          <>
            {preload && (desktop || tablet || mobile) && (
              <>
                {desktop && (
                  <PreloadLink
                    src={desktop}
                    media={getMedia(BreakpointKey.desktop)}
                  />
                )}
                {tablet && (
                  <PreloadLink
                    src={tablet}
                    media={getMedia(BreakpointKey.tablet)}
                  />
                )}
                {mobile && (
                  <PreloadLink
                    src={mobile}
                    media={getMedia(BreakpointKey.mobile)}
                  />
                )}
              </>
            )}
            <picture>
              {desktop && (
                <source
                  srcSet={desktop}
                  media={getMedia(BreakpointKey.desktop)}
                />
              )}
              {tablet && (
                <source
                  srcSet={tablet}
                  media={getMedia(BreakpointKey.tablet)}
                />
              )}
              {mobile && (
                <source
                  srcSet={mobile}
                  media={getMedia(BreakpointKey.mobile)}
                />
              )}
              <Image
                ref={ref}
                src={src}
                lazy={lazy}
                onLoad={onLoad}
                {...rest}
              />
            </picture>
          </>
        )}
      </>
    )
  }
)

type PictureWithPlaceholderProps = PictureProps & Placeholder

export const PictureWithPlaceholder: FC<PictureWithPlaceholderProps> = (
  props
) => {
  const { ratio, disableBackground, transition, ...rest } = props
  return (
    <PicturePlaceholder placeholder={{ ratio, disableBackground, transition }}>
      <InnerPicture {...rest} />
    </PicturePlaceholder>
  )
}

type ElementWithPlaceholderProps = React.PropsWithChildren<
  { elementClass: string } & Placeholder
>

export const ElementWithPlaceholder: FC<ElementWithPlaceholderProps> = ({
  ratio,
  disableBackground,
  transition,
  elementClass,
  children
}) => {
  return (
    <PicturePlaceholder
      placeholder={{ ratio, disableBackground, transition }}
      elementClass={elementClass}
    >
      {children}
    </PicturePlaceholder>
  )
}

const StyledImg = styled.img`
  width: 100%;
  display: block;
`

const Wrapper = styled(Box)`
  position: relative;
  ${({ disableBackground }) => !disableBackground && PlaceholderColor}
  ${({ elementClass, transition }) =>
    elementClass
      ? `
  && img.${elementClass} {
    width: 100%;
    display: block;    
    ${
      transition
        ? `opacity: 0; transition: opacity ${transition}s, background-color ${transition}s !important;`
        : ''
    }
  }
  && > :first-child {
    position: absolute;
  }
  `
      : `
    & img {
    width: 100%;
    display: block;
    position: absolute;
    ${
      transition
        ? `opacity: 0; transition: opacity ${transition}s, background-color ${transition}s;`
        : ''
    }
  }
  `}
`

import {
  getSdk as getBaseSdk,
  ResponseCode,
  Sdk,
  SdkFunctionWrapper
} from '@/network/graphql.g'
import { GraphQLClient } from 'graphql-request'
import { getHostData } from '@/providers/storeProvider'
import { ClientError } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import { MhubApiDataType } from '@/network/models/mhub'
import { isSSR, logApi } from '@/core/utils'
import { SSRSession, Session as IronSession } from '@/core/auth/session'
import {
  fetchDataWithCookie,
  getGraphQlUrl,
  getHeaders,
  isAbortError
} from '@/core/fetchData'
import { FetchError } from '@/core/auth/fetchError'
import { Cookie } from 'next-cookie'
import * as Sentry from '@sentry/nextjs'
import { SpanStatusType, spanStatusfromHttpCode } from '@sentry/tracing'

export type RequestResponse<T> = {
  success: boolean
  response: T
  abort: boolean
  error?: Rest
}

type RequestHeaders = Dom.RequestInit['headers']

type RequestMethod<T, V> = (
  variables: V,
  requestHeaders?: RequestHeaders
) => Promise<T>

export type RequestProps<T, V> = {
  method: RequestMethod<T, V>
  variables?: V
  requestHeaders?: RequestHeaders
  managedCookiesForRequest?: string[]
  managedCookiesForResponse?: string[]
  successPredicate?: (response?: T) => boolean
  mainResponse?: (response?: T) => Rest
}

export const getSdk = (
  session: SSRSession,
  options?: Dom.RequestInit
): { sdk: Sdk; apiData: MhubApiDataType } => {
  const apiData: unknown[] = []
  const logs: string[] = []

  const mhubSSRSdkWrapper: SdkFunctionWrapper = async <T>(
    action: () => Promise<T>,
    operationName: string
  ): Promise<T> => {
    return baseSdkWrapper(action, operationName, (result, log) => {
      logs.push(log)
      Object.keys(result).forEach(
        (key) => result[key] && apiData.push(result[key])
      )
    })
  }

  const sdk = getBaseSdk(
    makeGraphQLClient(
      session?.session,
      session?.host,
      session?.cookie,
      null,
      options
    ),
    mhubSSRSdkWrapper
  )
  return { sdk, apiData: { logs } }
}

export const makeGraphQLClient = (
  session: IronSession,
  host: string,
  cookie: Cookie,
  abortSignal?: AbortSignal,
  options?: Dom.RequestInit
): GraphQLClient => {
  const { domain, subdomain } = getHostData(host)
  return new GraphQLClient(getGraphQlUrl(domain, subdomain, cookie), {
    ...{
      headers: getHeaders(
        session?.isLoggedIn ? session?.accessToken : null,
        domain,
        subdomain,
        host
      ),
      fetch: fetchDataWithCookie(cookie),
      signal: abortSignal
    },
    ...options
  })
}

export const baseSdkWrapper = async <T>(
  action: () => Promise<T>,
  operationName: string,
  processData?: (result: T, log: string) => void
): Promise<T> => {
  const host = isSSR ? globalThis.HOST : window.location.host
  const transaction = Sentry.startTransaction({
    name: `/api/graphql/${operationName}`,
    tags: {
      type: 'API call',
      operationName,
      host,
      market: getHostData(host)?.subdomain,
      initiator: isSSR ? 'server' : 'client'
    },
    op: operationName
  })
  const span = transaction.startChild({ op: operationName })
  try {
    const startTime = new Date().getTime()
    const result = await action()
    const endTime = new Date().getTime()
    const log = `${
      typeof window === 'undefined' ? 'server' : 'client'
    } ${operationName}: ${endTime - startTime}ms`
    // eslint-disable-next-line no-console
    logApi && console.log(`api log ${log}`)
    processData && processData(result, log)
    span.setStatus('ok' as SpanStatusType)
    transaction.setTag('graphQlStatus', 'ok')
    return result
  } catch (e) {
    if (!isAbortError(e)) {
      // eslint-disable-next-line no-empty
      if (e instanceof FetchError) {
        span.setStatus(spanStatusfromHttpCode(e.getStatus()))
        transaction.setTag(
          'graphQlStatus',
          `1 - ${spanStatusfromHttpCode(e.getStatus())}`
        )
        // eslint-disable-next-line no-console
        logApi && console.log(e.toJSON())
      } else if (e instanceof ClientError) {
        if (e?.response?.status) {
          span.setStatus(spanStatusfromHttpCode(e?.response?.status))
          transaction.setTag(
            'graphQlStatus',
            `2 - ${spanStatusfromHttpCode(e?.response?.status)}`
          )
        } else {
          span.setStatus('unknown_error' as SpanStatusType)
          transaction.setTag('graphQlStatus', `3 - unknown_error`)
        }
        e.name = 'ClientError'
        // eslint-disable-next-line no-console
        logApi && console.log(e)
      } else {
        span.setStatus('unknown_error' as SpanStatusType)
        transaction.setTag('graphQlStatus', `4 - unknown_error`)
      }

      /*Sentry.withScope((scope) => {
        scope.setExtra('error', e)
        Sentry.captureException(e)
      })*/
    } else {
      span.setStatus('aborted' as SpanStatusType)
    }
    throw e
  } finally {
    span.finish()
    transaction.finish()
  }
}

export async function requestThrow401<T, V>(
  props: RequestProps<T, V>
): Promise<RequestResponse<T>> {
  const { error, ...rest } = await request(props)
  if (error?.is401 && error.is401()) {
    // eslint-disable-next-line no-console
    console.error(
      'Error 401 2nd level',
      new Date(),
      error.getAuthorizationHeader()
    )
    throw new Error(error?.getStatus())
  }
  return { error, ...rest }
}

export async function request<T, V>({
  method,
  variables,
  requestHeaders,
  managedCookiesForRequest,
  managedCookiesForResponse,
  successPredicate,
  mainResponse: mainResponseFunc,
  allowRetry = true
}: RequestProps<T, V> & { allowRetry?: boolean }): Promise<RequestResponse<T>> {
  try {
    if (!requestHeaders) {
      requestHeaders = {}
    }
    if (managedCookiesForRequest?.length > 0) {
      requestHeaders['managedCookiesForRequest'] = JSON.stringify(
        managedCookiesForRequest
      )
    }
    if (managedCookiesForResponse?.length > 0) {
      requestHeaders['managedCookiesForResponse'] = JSON.stringify(
        managedCookiesForResponse
      )
    }
    const response = await method(variables, requestHeaders)
    const mainResponse = getMainResponse(response, mainResponseFunc)

    let success = false
    if (successPredicate) {
      success = successPredicate(response)
    } else if (mainResponse && typeof mainResponse === 'object') {
      success =
        !('responseCode' in mainResponse) ||
        mainResponse['responseCode'] === ResponseCode.Ok
    } else if (response) {
      success = true
    }
    return { success, response, abort: false }
  } catch (e) {
    if (allowRetry && e?.is401 && e.is401()) {
      // eslint-disable-next-line no-console
      console.error(
        'Error 401 1st level',
        new Date(),
        e.getAuthorizationHeader()
      )
      requestHeaders['Authorization'] = ''
      return request({
        method,
        variables,
        requestHeaders,
        managedCookiesForRequest,
        managedCookiesForResponse,
        successPredicate,
        mainResponse: mainResponseFunc,
        allowRetry: false
      })
    }
    return {
      success: false,
      response: null,
      abort: isAbortError(e),
      error: e
    }
  }
}

export function getMainResponse<T>(
  response: T,
  mainResponse?: (response?: T) => unknown
): unknown {
  if (mainResponse) {
    return mainResponse(response)
  } else if (response && Object.keys(response).length === 1) {
    return response[Object.keys(response)[0]]
  } else {
    return null
  }
}

export const isOk = (responseCode: ResponseCode): boolean =>
  responseCode === ResponseCode.Ok

export const is401 = (responseCode: ResponseCode): boolean =>
  responseCode === ResponseCode.Error_401

export const is400 = (responseCode: ResponseCode): boolean =>
  responseCode === ResponseCode.Error_400

import {
  Blog,
  Campaign,
  Category,
  GiftBox,
  Manufacturer,
  Page,
  Product,
  SeoTags
} from '@/network/graphql.g'
import { useContext, useEffect, useRef } from 'react'
import { CurrencyContext } from '@/providers/currencyProvider'
import { useDomain, useLocale } from '@/providers/storeProvider'
import { getMhubProductData, MhubProductData } from './productManager'
import { parseFilterQueryIdsFromQuery } from '../filterProvider/parsingFilters'
import { useRouter } from 'next/router'
import { getPageFromRouterOrDefault } from '@/utils/urlHelper'
import { SlugPathItem } from '@/types/slugPath'
import { getImageMode } from '@/utils/imageExtension'

type BaseMhubPageData = {
  locale: string
  fullReferral: string
  currencyCode: string
  localCurrencyCode: string
  trail: string
  type: string
  kind: string
  layout: string
  error?: {
    type: string
    message: string
  }
}

type MhubPageData = {
  title?: string
  checkout?: {
    step: number
  }
} & BaseMhubPageData

export type MhubPage<T> = {
  page: T
}

type ProductDetailMhubPage = {
  detail: { products: MhubProductData[] }
} & MhubPageData

type ProductListingMhubPage = {
  list: ProductListingList
} & MhubPageData

type ProductListingList = {
  filters: {
    category: string[]
    colour: string[]
    material: string[]
    in_stock: boolean
    manufacturer: number[]
    new_products: boolean
    price: { min: number; max: number }
    size: string[]
    size_table_item: string[]
  }
  pageNumber: number
  sorting: string
  searchresults?: MhubSearchResults
  category?: string
  categoryId?: number
}

type MhubSearchResults = {
  resultCount: number
  term: string
  type: string
}

const defaultPageData = { trail: null, type: 'other', kind: null, layout: '' }

enum MhubPages {
  SearchWhisperer = 'searchWhisperer',
  WarehouseProductListing = 'warehouseProductListing',
  Micropage = 'micropage',
  ThankYou = 'thankYou',
  Error = 'error',
  Step3Duplicate = 'step3Duplicate',
  Top100 = 'top100',
  BrandsLandingPage = 'brandsLandingPage',
  Gifts = 'Gifts',
  GiftsProductListing = 'GiftsProductListing'
}

const PageData = {
  [Page.AccountEditUserInfo]: {
    ...defaultPageData,
    ...{
      type: 'account',
      kind: 'account'
    }
  },
  [Page.OrderDetail]: {
    ...defaultPageData,
    ...{
      type: 'account.order.detail',
      kind: 'account'
    }
  },
  [Page.ReturnDetail]: {
    ...defaultPageData,
    ...{
      type: 'account.return.detail',
      kind: 'account'
    }
  },
  [Page.ReturnAddForm]: {
    ...defaultPageData,
    ...{
      type: 'account.return.add',
      kind: 'account'
    }
  },
  [Page.ReturnSuccess]: {
    ...defaultPageData,
    ...{
      type: 'account.return.success',
      kind: 'account'
    }
  },
  [Page.AccountOrders]: {
    ...defaultPageData,
    ...{
      type: 'account.order.list',
      kind: 'account'
    }
  },
  [Page.AddAddress]: {
    ...defaultPageData,
    ...{
      type: 'account.address.add',
      kind: 'account'
    }
  },
  [Page.AccountNewsletter]: {
    ...defaultPageData,
    ...{
      type: 'account.newsletter',
      kind: 'account'
    }
  },
  [Page.AccountPassword]: {
    ...defaultPageData,
    ...{
      type: 'account.password.edit',
      kind: 'account'
    }
  },
  [Page.Campaigns]: {
    ...defaultPageData,
    ...{
      type: 'microsite.campaign.list',
      kind: 'custom'
    }
  },
  [Page.CampaignProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'campaign'
    }
  },
  [Page.Blogs]: {
    ...defaultPageData,
    ...{
      type: 'microsite.blog.list',
      kind: 'custom'
    }
  },
  [Page.BlogDetail]: {
    ...defaultPageData,
    ...{
      type: 'detail',
      kind: 'blog'
    }
  },
  [Page.CategoryProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'category'
    }
  },
  [Page.DiscountProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'discount'
    }
  },
  [MhubPages.WarehouseProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'stock'
    }
  },
  [MhubPages.GiftsProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'gifts'
    }
  },
  [Page.GiftCardCheckout]: {
    ...defaultPageData,
    ...{ type: 'giftCard.checkout', kind: 'giftCard' }
  },
  [Page.GiftCardSuccess]: {
    ...defaultPageData,
    ...{ type: 'giftCard.checkout.success', kind: 'giftCard' }
  },
  [Page.GiftCard]: {
    ...defaultPageData,
    ...{ type: 'giftCard', kind: 'giftCard' }
  },
  [MhubPages.Gifts]: {
    ...defaultPageData,
    ...{ type: 'gifts', kind: 'gifts' }
  },
  [Page.InformationPages]: {
    ...defaultPageData,
    ...{ type: 'microsite.information', kind: 'custom' }
  },
  [Page.About]: {
    ...defaultPageData,
    ...{ type: 'microsite.about', kind: 'custom' }
  },
  [Page.B2b]: {
    ...defaultPageData,
    ...{ type: 'microsite.b2b', kind: 'custom' }
  },
  [Page.Impressum]: {
    ...defaultPageData,
    ...{ type: 'microsite.impressum', kind: 'custom' }
  },
  [Page.Shop]: {
    ...defaultPageData,
    ...{ type: 'microsite.shop', kind: 'custom' }
  },
  [Page.LandingPage]: {
    ...defaultPageData,
    ...{ type: 'microsite.landingpage', kind: 'custom' }
  },
  [MhubPages.Micropage]: {
    ...defaultPageData,
    ...{ type: 'microsite.micropage', kind: 'custom' }
  },
  [Page.ProductDetail]: {
    ...defaultPageData,
    ...{
      type: 'detail',
      kind: 'product'
    }
  },
  [Page.ResetPassword]: {
    ...defaultPageData,
    ...{ type: 'account.password.lost', kind: 'account' }
  },
  [Page.SpecialLandingPage]: {
    ...defaultPageData,
    ...{ type: 'microsite.speciallandingpage', kind: 'custom' }
  },
  [Page.Wishlist]: {
    ...defaultPageData,
    ...{ type: 'wishlist', kind: 'custom' }
  },
  [Page.AdventCalendar]: {
    ...defaultPageData,
    ...{ type: 'microsite.adventcalendar', kind: 'custom' }
  },
  [Page.Cart]: {
    ...defaultPageData,
    ...{ type: 'checkout' }
  },
  [Page.CheckOrderStatus]: {
    ...defaultPageData,
    ...{ type: 'microsite.checkorderstatus', kind: 'custom' }
  },
  [Page.Step3]: {
    ...defaultPageData,
    ...{ type: 'purchase' }
  },
  [MhubPages.Step3Duplicate]: {
    ...defaultPageData
  },
  [Page.Contact]: {
    ...defaultPageData,
    ...{ type: 'microsite.contact', kind: 'custom' }
  },
  [Page.Hiring]: {
    ...defaultPageData,
    ...{ type: 'microsite.hiring', kind: 'custom' }
  },
  [Page.Homepage]: {
    ...defaultPageData,
    ...{ trail: 'Home', type: 'home' }
  },
  [Page.Login]: {
    ...defaultPageData,
    ...{ type: 'account.login', kind: 'account' }
  },
  [Page.LostPassword]: {
    ...defaultPageData,
    ...{ type: 'account.password.lost', kind: 'account' }
  },
  [Page.Manufacturers]: {
    ...defaultPageData,
    ...{
      type: 'microsite.manufacturer.list',
      kind: 'custom'
    }
  },
  [Page.ManufacturerProductListing]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'brand'
    }
  },
  [Page.Register]: {
    ...defaultPageData,
    ...{ type: 'account.register', kind: 'account' }
  },
  [Page.Return]: {
    ...defaultPageData,
    ...{ type: 'account.return', kind: 'account' }
  },
  [Page.Search]: {
    ...defaultPageData,
    ...{
      type: 'list.searchresult',
      kind: 'searchresult'
    }
  },
  [Page.Step1]: {
    ...defaultPageData,
    ...{ type: 'checkout' }
  },
  [Page.Step2]: {
    ...defaultPageData,
    ...{ type: 'checkout' }
  },
  [MhubPages.ThankYou]: {
    ...defaultPageData,
    ...{ type: 'microsite.thankyou', kind: 'custom' }
  },
  [MhubPages.SearchWhisperer]: {
    ...defaultPageData,
    ...{
      type: 'list.searchresult',
      kind: 'whisperer'
    }
  },
  [MhubPages.Error]: {
    ...defaultPageData,
    ...{
      type: 'error',
      kind: 'error'
    }
  },
  [MhubPages.Top100]: {
    ...defaultPageData,
    ...{
      type: 'list',
      kind: 'top100',
      trail: 'topproducts'
    }
  },
  [MhubPages.BrandsLandingPage]: {
    ...defaultPageData,
    ...{ type: 'microsite.brandslandingpage', kind: 'custom' }
  }
}

export const useMhubPage = (
  referer: string,
  seoTags: SeoTags
): {
  getEmptyPage: () => MhubPage<MhubPageData>
  getAccountEditPage: (id: string) => MhubPage<MhubPageData>
  getOrderDetailPage: (id: string) => MhubPage<MhubPageData>
  getReturnDetailPage: (id: string) => MhubPage<MhubPageData>
  getAddReturnPage: (id: string) => MhubPage<MhubPageData>
  getReturnSuccessPage: () => MhubPage<MhubPageData>
  getOrdersPage: () => MhubPage<MhubPageData>
  getAddAddressPage: () => MhubPage<MhubPageData>
  getNewsletterPage: () => MhubPage<MhubPageData>
  getPasswordPage: () => MhubPage<MhubPageData>
  getCampaignPage: () => MhubPage<MhubPageData>
  getCampaignDetailPage: (
    campaign: Campaign,
    page?: number
  ) => MhubPage<MhubPageData>
  getBlogPage: () => MhubPage<MhubPageData>
  getBlogDetailPage: (blog: Blog, page?: number) => MhubPage<MhubPageData>
  getCategoryDetailPage: (
    category: SlugPathItem,
    page?: number
  ) => MhubPage<MhubPageData>
  getDiscountDetailPage: (
    category: Category,
    page?: number
  ) => MhubPage<MhubPageData>
  getWarehouseDetailPage: (
    category: Category,
    page?: number
  ) => MhubPage<MhubPageData>
  getGiftsDetailPage: (
    giftBox: GiftBox,
    page?: number
  ) => MhubPage<MhubPageData>
  getGiftCardCheckoutPage: () => MhubPage<MhubPageData>
  getGiftCardCheckoutSuccessPage: () => MhubPage<MhubPageData>
  getGiftCardPage: () => MhubPage<MhubPageData>
  getInformationPage: (name: string) => MhubPage<MhubPageData>
  getAboutPage: () => MhubPage<MhubPageData>
  getB2bPage: () => MhubPage<MhubPageData>
  getImpressumPage: () => MhubPage<MhubPageData>
  getShopPage: () => MhubPage<MhubPageData>
  getLandingPagePage: () => MhubPage<MhubPageData>
  getMicropagePage: () => MhubPage<MhubPageData>
  getProductDetailPage: (product: Product) => MhubPage<ProductDetailMhubPage>
  getProductDetailPopupPage: (
    product: Product
  ) => MhubPage<ProductDetailMhubPage>
  getResetPasswordPage: () => MhubPage<MhubPageData>
  getSpecialLandingPagePage: (
    manufacturer: Manufacturer
  ) => MhubPage<MhubPageData>
  getBrandsLandingPage: () => MhubPage<MhubPageData>
  getWishlistPage: () => MhubPage<MhubPageData>
  getAdventCalendarPage: () => MhubPage<MhubPageData>
  getCartPage: () => MhubPage<MhubPageData>
  getCheckOrderPage: () => MhubPage<MhubPageData>
  getCheckoutSuccessPage: (orderId: string) => MhubPage<MhubPageData>
  getContactPage: () => MhubPage<MhubPageData>
  getHiringPage: () => MhubPage<MhubPageData>
  getHomePage: () => MhubPage<MhubPageData>
  getLoginPage: () => MhubPage<MhubPageData>
  getLostPasswordPage: () => MhubPage<MhubPageData>
  getGiftsPage: () => MhubPage<MhubPageData>
  getManufacturerPage: () => MhubPage<MhubPageData>
  getManufacturerDetailPage: (
    manufacturer: Manufacturer,
    page?: number
  ) => MhubPage<MhubPageData>
  getRegisterPage: () => MhubPage<MhubPageData>
  getReturnPage: () => MhubPage<MhubPageData>
  getSearchDetailPage: (
    term: string,
    resultCount: number,
    page?: number
  ) => MhubPage<MhubPageData>
  getStep1Page: () => MhubPage<MhubPageData>
  getStep2Page: () => MhubPage<MhubPageData>
  getThankYouPage: () => MhubPage<MhubPageData>
  getSearchWhispererPage: (
    term: string,
    resultCount: number
  ) => MhubPage<MhubPageData>
  getErrorPage: (type: string, message: string) => MhubPage<MhubPageData>
  getTop100Page: (page?: number) => MhubPage<MhubPageData>
} => {
  const { domainName, subdomainName } = useDomain()
  const locale = useLocale()
  const currency = useContext(CurrencyContext)
  const intervalHandler = useRef<NodeJS.Timeout>(null)
  const router = useRouter()

  useEffect(() => {
    return clearIntervalHandler
  }, [])

  const clearIntervalHandler = () => {
    clearInterval(intervalHandler.current)
  }

  const getEmptyPage = (): MhubPage<MhubPageData> => ({ page: null })

  const getAccountEditPage = (id: string): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.AccountEditUserInfo),
      ...{
        title: seoTags?.title,
        trail: `account>edit>${id}`
      }
    }
  })

  const getOrderDetailPage = (id: string): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.OrderDetail),
      ...{
        title: seoTags?.title,
        trail: `account>orders>order>${id}`
      }
    }
  })

  const getReturnDetailPage = (id: string): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.ReturnDetail),
      ...{
        title: seoTags?.title,
        trail: `account>orders>return>${id}`
      }
    }
  })

  const getAddReturnPage = (id: string): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.ReturnAddForm),
      ...{
        title: seoTags?.title,
        trail: `account>orders>return>add>${id}`
      }
    }
  })

  const getReturnSuccessPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.ReturnSuccess),
      ...{
        title: seoTags?.title,
        trail: 'account>orders>return>success'
      }
    }
  })

  const getOrdersPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.AccountOrders),
      ...{
        title: seoTags?.title,
        trail: 'account>orders'
      }
    }
  })

  const getAddAddressPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.AddAddress),
      ...{
        title: seoTags?.title,
        trail: 'account>add'
      }
    }
  })

  const getNewsletterPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.AccountNewsletter),
      ...{
        title: seoTags?.title,
        trail: 'account>newsletter'
      }
    }
  })

  const getPasswordPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.AccountPassword),
      ...{
        title: seoTags?.title,
        trail: 'account>password'
      }
    }
  })

  const getCampaignPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Campaigns, seoTags?.title)

  const getCampaignDetailPage = (
    campaign: Campaign,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(Page.CampaignProductListing),
      ...{
        list: getListData({}, page),
        title: seoTags?.title,
        trail: `campaign>${campaign?.name}`
      }
    }
  })

  const getBlogPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Blogs, seoTags?.title)

  const getBlogDetailPage = (
    blog: Blog,
    page?: number
  ): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.BlogDetail),
      ...{
        list: getListData({}, page),
        title: seoTags?.title,
        trail: `blog>${blog?.title}`
      }
    }
  })

  const getCategoryDetailPage = (
    category: SlugPathItem,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(Page.CategoryProductListing),
      ...{
        list: {
          ...getListData({ category: category?.id }, page),
          ...{
            category: category?.slug,
            categoryId: category?.id ? +category.id : null
          }
        },
        title: seoTags?.title,
        trail: category?.slug
      }
    }
  })

  const getDiscountDetailPage = (
    category: Category,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(Page.DiscountProductListing),
      ...{
        list: getListData({ category: category?.id }, page),
        title: seoTags?.title,
        trail: category?.slug
      }
    }
  })

  const getWarehouseDetailPage = (
    category: Category,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(MhubPages.WarehouseProductListing),
      ...{
        list: getListData({ category: category?.id }, page),
        title: seoTags?.title,
        trail: category?.slug
      }
    }
  })

  const getGiftsDetailPage = (
    giftBox: GiftBox,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(MhubPages.GiftsProductListing),
      ...{
        list: getListData({}, page),
        title: seoTags?.title,
        trail: giftBox?.id
      }
    }
  })

  const getGiftCardCheckoutPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.GiftCardCheckout, seoTags?.title)

  const getGiftCardCheckoutSuccessPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.GiftCardSuccess, seoTags?.title)

  const getGiftCardPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.GiftCard, seoTags?.title)

  const getInformationPage = (name: string): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.InformationPages),
      ...{
        title: seoTags?.title,
        trail: `information>${name}`
      }
    }
  })

  const getAboutPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.About),
      ...{
        title: seoTags?.title,
        trail: 'information>about'
      }
    }
  })

  const getB2bPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.B2b),
      ...{
        title: seoTags?.title,
        trail: 'information>b2b'
      }
    }
  })

  const getImpressumPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Impressum),
      ...{
        title: seoTags?.title,
        trail: 'information>impressum'
      }
    }
  })

  const getShopPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Shop),
      ...{
        title: seoTags?.title,
        trail: 'information>shop'
      }
    }
  })

  const getLandingPagePage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.LandingPage, seoTags?.title)

  const getMicropagePage = (): MhubPage<MhubPageData> =>
    getCommonPage(MhubPages.Micropage, seoTags?.title)

  const getProductDetailPage = (
    product: Product
  ): MhubPage<ProductDetailMhubPage> => getBaseProductDetailPage(product, '')

  const getProductDetailPopupPage = (
    product: Product
  ): MhubPage<ProductDetailMhubPage> =>
    getBaseProductDetailPage(product, 'quickbuy')

  const getResetPasswordPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.ResetPassword, seoTags?.title)

  const getSpecialLandingPagePage = (
    manufacturer: Manufacturer
  ): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.SpecialLandingPage),
      ...{
        title: seoTags?.title,
        trail: `landing-page>${manufacturer?.name}`
      }
    }
  })

  const getBrandsLandingPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(MhubPages.BrandsLandingPage),
      ...{
        title: seoTags?.title,
        trail: `landing-page>brands`
      }
    }
  })

  const getWishlistPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Wishlist, seoTags?.title)

  const getAdventCalendarPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.AdventCalendar, seoTags?.title)

  const getCartPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Cart),
      ...{
        title: seoTags?.title,
        checkout: {
          step: 1
        }
      }
    }
  })

  const getCheckOrderPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.CheckOrderStatus, seoTags?.title)

  const getGiftsPage = (): MhubPage<MhubPageData> =>
    getCommonPage(MhubPages.Gifts, seoTags?.title)

  const getCheckoutSuccessPage = (orderId: string): MhubPage<MhubPageData> =>
    canPushCheckoutSuccessPage(orderId)
      ? getCommonPage(Page.Step3, seoTags?.title)
      : getCommonPage(MhubPages.Step3Duplicate, seoTags?.title)

  const getContactPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Contact),
      ...{
        title: seoTags?.title,
        trail: 'contact'
      }
    }
  })

  const getHiringPage = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Hiring),
      ...{
        title: seoTags?.title,
        trail: 'hiring'
      }
    }
  })

  const getHomePage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Homepage, seoTags?.title)

  const getLoginPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Login, seoTags?.title)

  const getLostPasswordPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.LostPassword, seoTags?.title)

  const getManufacturerPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Manufacturers, seoTags?.title)

  const getManufacturerDetailPage = (
    manufacturer: Manufacturer,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(Page.ManufacturerProductListing),
      ...{
        list: getListData({ manufacturer: manufacturer?.id }, page),
        title: seoTags?.title,
        trail: `manufacturer>${manufacturer?.name}`
      }
    }
  })

  const getRegisterPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Register, seoTags?.title)

  const getReturnPage = (): MhubPage<MhubPageData> =>
    getCommonPage(Page.Return, seoTags?.title)

  const getSearchDetailPage = (
    term: string,
    resultCount: number
  ): MhubPage<ProductListingMhubPage> =>
    getBaseSearchPage(Page.Search, term, resultCount)

  const getStep1Page = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Step1),
      ...{
        title: seoTags?.title,
        checkout: {
          step: 2
        }
      }
    }
  })

  const getStep2Page = (): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(Page.Step2),
      ...{
        title: seoTags?.title,
        checkout: {
          step: 3
        }
      }
    }
  })

  const getThankYouPage = (): MhubPage<MhubPageData> =>
    getCommonPage(MhubPages.ThankYou, seoTags?.title)

  const getSearchWhispererPage = (
    term: string,
    resultCount: number
  ): MhubPage<ProductListingMhubPage> =>
    getBaseSearchPage(MhubPages.SearchWhisperer, term, resultCount)

  const getBaseSearchPage = (
    pageType: Page | MhubPages,
    term: string,
    resultCount: number,
    page?: number
  ): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(pageType),
      ...{
        list: {
          ...getListData({}, page),
          ...{
            searchresults: {
              resultCount,
              term,
              type: 'fulltext'
            }
          }
        }
      }
    }
  })

  const getListData = (
    {
      manufacturer,
      category
    }: {
      manufacturer?: string
      category?: string
    },
    page?: number
  ): ProductListingList => {
    const filter = parseFilterQueryIdsFromQuery(router.query)
    return {
      filters: {
        category: [
          ...(filter.categories ?? []),
          ...(category ? [category] : [])
        ],
        colour: filter.colors ?? [],
        material: filter.materials ?? [],
        in_stock: filter.inStock,
        manufacturer: [
          ...(filter.manufacturers?.map((item) => +item) ?? []),
          ...(manufacturer ? [+manufacturer] : [])
        ],
        new_products: filter.news,
        price: {
          min: filter.price?.min ?? null,
          max: filter.price?.max ?? null
        },
        size: [],
        size_table_item: filter.sizes ?? []
      },
      pageNumber: page ?? getPageFromRouterOrDefault(router),
      sorting: filter.sort ?? 'top'
    }
  }

  const getBaseProductDetailPage = (
    product: Product,
    layout: string
  ): MhubPage<ProductDetailMhubPage> => ({
    page: {
      ...getBasePage(Page.ProductDetail),
      ...{
        detail: {
          products: [getMhubProductData(product, getImageMode(subdomainName))]
        },
        title: product?.name,
        trail: [
          product?.MHubProperties?.category,
          product?.MHubProperties?.name
        ].join('>'),
        layout
      }
    }
  })

  const getCommonPage = (
    page: Page | MhubPages,
    title?: string
  ): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(page),
      ...(title ? { title } : {})
    }
  })

  const getBasePage = (page: Page | MhubPages): BaseMhubPageData => ({
    ...{
      locale,
      fullReferral: getReferer(),
      currencyCode: currency?.currencyCode,
      localCurrencyCode: currency?.localCurrencyCode
    },
    ...PageData[page]
  })

  const getReferer = (): string => {
    if (referer != null && domainName != null) {
      const parsedUrl = new URL(referer)
      const regex = new RegExp(`^([a-z0-9]+[.])*${domainName}[.]([a-z0-9])*$`)
      if (!parsedUrl.hostname.match(regex)) {
        return referer
      }
    }
    return null
  }

  const getErrorPage = (
    type: string,
    message: string
  ): MhubPage<MhubPageData> => ({
    page: {
      ...getBasePage(MhubPages.Error),
      ...{
        error: {
          type,
          message
        }
      }
    }
  })

  const canPushCheckoutSuccessPage = (orderId: string): boolean => {
    try {
      const deduperKey = `mhub-deduper-order-id-${orderId}`
      if (localStorage.getItem(deduperKey)?.toString() ?? '' === orderId) {
        return false
      } else {
        localStorage.setItem(deduperKey, orderId)
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return true
  }

  const getTop100Page = (page?: number): MhubPage<ProductListingMhubPage> => ({
    page: {
      ...getBasePage(MhubPages.Top100),
      ...{
        list: getListData({}, page),
        title: seoTags?.title
      }
    }
  })

  return {
    getEmptyPage,
    getAccountEditPage,
    getOrderDetailPage,
    getReturnDetailPage,
    getAddReturnPage,
    getReturnSuccessPage,
    getOrdersPage,
    getAddAddressPage,
    getNewsletterPage,
    getPasswordPage,
    getCampaignPage,
    getCampaignDetailPage,
    getBlogPage,
    getBlogDetailPage,
    getCategoryDetailPage,
    getDiscountDetailPage,
    getWarehouseDetailPage,
    getGiftsDetailPage,
    getGiftCardCheckoutPage,
    getGiftCardCheckoutSuccessPage,
    getGiftCardPage,
    getInformationPage,
    getAboutPage,
    getB2bPage,
    getImpressumPage,
    getShopPage,
    getLandingPagePage,
    getMicropagePage,
    getProductDetailPage,
    getProductDetailPopupPage,
    getResetPasswordPage,
    getSpecialLandingPagePage,
    getBrandsLandingPage,
    getWishlistPage,
    getAdventCalendarPage,
    getCartPage,
    getCheckOrderPage,
    getCheckoutSuccessPage,
    getContactPage,
    getHiringPage,
    getHomePage,
    getLoginPage,
    getLostPasswordPage,
    getManufacturerPage,
    getManufacturerDetailPage,
    getRegisterPage,
    getReturnPage,
    getSearchDetailPage,
    getStep1Page,
    getStep2Page,
    getThankYouPage,
    getSearchWhispererPage,
    getErrorPage,
    getTop100Page,
    getGiftsPage
  }
}

import { CountryId, StoreId } from '@/network/graphql.g'

interface TrackerData {
  storeId: Record<string, Record<string, string>>
}

export const trackerDatabreakerScript: TrackerData = {
  storeId: {
    [StoreId.Factcool]: {
      [CountryId.Hr]: 'factcool_hr',
      [CountryId.Ro]: 'factcool_ro'
    }
  }
}

export const trackerDatabreakerExperiment: TrackerData = {
  storeId: {
    [StoreId.Factcool]: {
      [CountryId.Gr]: 'factcool_gr'
    },
    [StoreId.Bezvasport]: {
      [CountryId.Cz]: 'factcool_cz'
    }
  }
}
